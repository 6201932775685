import { createSlice } from '@reduxjs/toolkit';

// Initial state of the layout slice with themeMode added
const initialState = {
  isSidebarOpen: false,
  isMobile: false,
  selectedSuite: "",
  layoutStyle: {
    margin: "0 auto",
    height: "100%",
    width: "100vw",
    
  },
  themeMode: 'light',  
  themeConfig: {
    palette: {
      primary: {
        light: '#f5f5f5',
        main: "#0055A5",
        dark: '#333333',
        success: "rgba(20, 220, 73, 0.5)",
        contrastText: '#fff',
      },
      secondary: {
        light: '#ff7961',
        main: '#f44336',
        dark: '#ba000d',
        contrastText: '#000',
      },
      success: {
        main: "#17B169",  // Updated success color
      },
    },
    typography: {
      fontFamily: 'Roboto, sans-serif',
    },
    MuiInputBase: {  
        styleOverrides: {
          input: {
            fontSize: '0.75rem', 
            color: "red"
          },
        },
    },
    components: {
      MuiPickersToolbar: {
        styleOverrides: {
          root: {
            color: '#1565c0',
            borderRadius: 2,
            borderWidth: 1,
            borderColor: '#2196f3',
            border: '1px solid',
            backgroundColor: '#bbdefb',
          }
        }
      },
      MuiDataGrid: {
        styleOverrides: {
          columnHeaders: {
            fontWeight: 'normal',
          },
        },
      },
    },
  },
};

export const layoutSlice = createSlice({
  name: 'layout',
  initialState,
  reducers: {
    openSidebar: (state) => {
      state.isSidebarOpen = true;
      state.layoutStyle = { ...state.layoutStyle, width: '100%' };
    },
    closeSidebar: (state) => {
      state.isSidebarOpen = false;
      state.layoutStyle = { ...state.layoutStyle, width: '100vw' };
    },
    // Action to toggle sidebar
    toggleSidebar: (state) => {
      state.isSidebarOpen = !state.isSidebarOpen;
      state.layoutStyle = {
        ...state.layoutStyle,
        width: (state.isSidebarOpen && !state.isMobile) ? 'calc(100vw - 300px)' : '100vw',
      };
    },
    setSelectedSuite: (state, action) => {
      state.selectedSuite = action.payload;
    },
    // Action to toggle the theme mode between 'light' and 'dark'
    toggleThemeMode: (state) => {
      state.themeMode = state.themeMode === 'light' ? 'dark' : 'light';
    },
    // Action to set state if mobile
    setIsMobile: (state, action) => {
      state.isMobile = action.payload;
    },
  },
});

// Export actions and reducer
export const { openSidebar, closeSidebar, toggleSidebar, toggleThemeMode, setIsMobile, setSelectedSuite } = layoutSlice.actions;

export default layoutSlice.reducer;
