import React, { useEffect, useState } from 'react';
import { useAppDispatch, useAppSelector } from '../../../app/hooks';
import { useNavigate } from 'react-router-dom'
import { logout, reset } from '../../auth/authSlice'
import Box from '@mui/material/Box';
import Drawer from '@mui/material/Drawer';
import List from '@mui/material/List';
import Divider from '@mui/material/Divider';
import ListItem from '@mui/material/ListItem';
import ListItemButton from '@mui/material/ListItemButton';
import ListItemIcon from '@mui/material/ListItemIcon';
import ListItemText from '@mui/material/ListItemText';
import LogoutSharpIcon from '@mui/icons-material/LogoutSharp';
import { Typography, useTheme } from '@mui/material';
import { toggleSidebar } from '../../layout/layoutSlice';
import usePageLinks from '../../components/hooks/usePageLinks';
const widthOpen = 300;
const widthClosed = 0;

export default function SideNavbar(_props:any) {
  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  const {isAuthenticated, suiteDetailsConfirmed} = useAppSelector((state:any) => state.auth);
  const {isSidebarOpen} = useAppSelector((state:any) => state.layout); 
  const [drawerWidth, setDrawerWidth] = useState(0);
  const theme = useTheme();
  const pages = usePageLinks();

  useEffect(() => {
    const width = isSidebarOpen ? widthOpen : widthClosed; 
    setDrawerWidth(width);
  },[isAuthenticated, isSidebarOpen]); 


  const handleLogout = async () => {
    await dispatch(logout());
    dispatch(reset());
    navigate('/login');
  }

  const handleNavigation = (path:string) => {
    dispatch(toggleSidebar());
    navigate(path);
  }

  return (
    <>
    {suiteDetailsConfirmed &&
      <Drawer
        variant="permanent"
        sx={{
          width: drawerWidth,
          flexShrink: 0,
          
          '& .MuiDrawer-paper': {
            position: "fixed",
            top: "64px",
            height: "100%",
            width: drawerWidth,
            backgroundColor: theme.palette.mode === 'dark' ? theme.palette.primary.dark : theme.palette.primary.main,
            color: "white",
            mt: "0.05em",
            opacity: 1,
            fontWeight: 600,
            boxSizing: 'border-box',
            boxShadow: '2px 0 5px rgba(0, 0, 0, 0.1)',
            borderRight: `0.25em solid ${theme.palette.mode === 'dark' ? theme.palette.primary.light : theme.palette.primary.main}`,
            borderRadius: 0.5,
            zIndex: 0,
            transition: theme.transitions.create('width', {
              easing: theme.transitions.easing.easeIn,
              duration: theme.transitions.duration.enteringScreen,
            }),
          },
        }}
      >

          <List sx={{height: "100%"}}>
            {pages?.map((page) => (
              <React.Fragment key={page?.id}>
                
                <Box sx={{ display: "flex", alignItems: "center", height: "5rem", width: "100%",  opacity: isSidebarOpen ? 1 : 0}}>
                  <ListItem component="label" disablePadding>
                    <ListItemButton onClick={() => handleNavigation(page?.link)}>
                      <ListItemIcon sx={{color: "white", ml: 1}}>{page?.icon}</ListItemIcon>
                      <Box sx={{ display: "flex", flexDirection: "column", width: "100%", textAlign: "start" }}>
                        <ListItemText 
                          sx={{color: "white", ml: 1}} 
                          primary={
                            <Typography variant='body1' sx={{ fontSize: "1rem", fontWeight: 600}}>
                              {page?.title}
                            </Typography>
                          } 
                        />
                        {/* <ListItemText
                          sx={{color: "white", ml: 1}} 
                          secondary={
                            <Typography variant='body2' sx={{fontWeight: 600, fontSize: 12}}>
                              {page?.desc}
                            </Typography>
                          }
                        /> */}
                      </Box>
                    </ListItemButton>          
                  </ListItem>
                </Box>
                <Divider sx={{backgroundColor: "white", height: "2px"}} />
              </React.Fragment>
            ))}
            {
              <Box sx={{ opacity: isSidebarOpen ? 1 : 0}}>
                <ListItem key="logout" disablePadding>
                  <ListItemButton onClick={handleLogout}>
                    <ListItemIcon sx={{color: "white", ml: 1}}><LogoutSharpIcon fontSize='large'/></ListItemIcon>
                    <ListItemText primary={
                      <Typography sx={{fontWeight: 600, ml: 1, fontSize: "1rem"}}>
                        Logout
                      </Typography>
                    } />
                  </ListItemButton>
                </ListItem>
              </Box>  
            }
          </List>

      </Drawer>
    }
    </>
    
  );
}