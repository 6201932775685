import { useState, useEffect, useMemo } from 'react';
import AppBar from '@mui/material/AppBar';
import Box from '@mui/material/Box';
import Toolbar from '@mui/material/Toolbar';
import IconButton from '@mui/material/IconButton';
import Typography from '@mui/material/Typography';
import Menu from '@mui/material/Menu';
import Avatar from '@mui/material/Avatar';
import Tooltip from '@mui/material/Tooltip';
import MenuItem from '@mui/material/MenuItem';
import { useNavigate, useLocation } from 'react-router-dom';
import { useAppDispatch, useAppSelector } from '../../../app/hooks';
import { logout, reset, setSuiteDetails } from '../../auth/authSlice';
import {Link, useTheme, FormControl, InputLabel, Select, useMediaQuery } from '@mui/material';
import MenuSharpIcon from '@mui/icons-material/MenuSharp';
import MenuOpenSharpIcon from '@mui/icons-material/MenuOpenSharp';
import Brightness4Icon from '@mui/icons-material/Brightness4'; // Icon for dark theme
import Brightness7Icon from '@mui/icons-material/Brightness7'; // Icon for light theme
import { toggleSidebar, toggleThemeMode } from '../layoutSlice';
import {ReactComponent as Logo} from '../../../assets/Logo.svg';
import { useGetCompanyNumbersQuery, useGetDivisionNumbersQuery } from '../../dropdowns/dropdownApi';
import usePageLinks from '../../components/hooks/usePageLinks';
import React from 'react';
import styles from './appBar.module.css';

function ResponsiveAppBar() {
  const navigate = useNavigate();
  const dispatch = useAppDispatch();
  const location = useLocation();
  const {isSidebarOpen, themeMode} = useAppSelector((state:any) => state.layout);
  const {company, division, suiteDetailsConfirmed}= useAppSelector((state:any) => state.auth);
  const [anchorElNav, setAnchorElNav] = useState<null | HTMLElement>(null);
  const [anchorElUser, setAnchorElUser] = useState<null | HTMLElement>(null);
  const [companySelection, setCompanySelection] = useState<any>({});
  const [divisionSelection, setDivisionSelection] = useState<any>({});
  const [logoutUser] = useState(false);
  const { data: company_numbers } = useGetCompanyNumbersQuery();
  const params = useMemo(() => ({ company_id: companySelection?._id }), [companySelection]);
  const { data: division_numbers } = useGetDivisionNumbersQuery(params);
  const theme = useTheme();
  const userGuideLink = process.env.REACT_APP_USER_GUIDE_LINK || "";
  const isOrgUnitRoute = useMemo(() => location.pathname.includes('organizational-units'), [location.pathname]);
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'));
  const pages = usePageLinks();

  useEffect(() => {
    if (company && division) {
      setCompanySelection(company);
      setDivisionSelection(division);
    }
  }, [company, division]);

  const appBarStyle = {
    backgroundColor: theme.palette.mode === 'dark' ? theme.palette.primary.dark : theme.palette.primary.light,
    borderBottom: `0.25em solid ${theme.palette.primary.light}`,
    color: theme.palette.text.primary,
  };
  
  useEffect(() => {
    if (
      companySelection &&
      Object.keys(companySelection).length > 0 &&
      divisionSelection &&
      Object.keys(divisionSelection).length > 0
    ) {
      dispatch(
        setSuiteDetails({
          company: companySelection,
          division: divisionSelection,
        })
      );
    }
  }, [companySelection, divisionSelection, dispatch]);    
  
  const handleCompanyNumberChange = (event:any) => {
    setCompanySelection(event.target.value);
    navigate('/');
  };

  const handleDivisionNumberChange = (event:any) => {
    setDivisionSelection(event.target.value);
  };

  const  handleClick = (event: React.MouseEvent<HTMLElement>) => {
    dispatch(toggleSidebar());
    setAnchorElNav(event.currentTarget);
  }
  const handleOpenUserMenu = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorElUser(event.currentTarget);
  };

  const handleCloseUserMenu = () => {
    setAnchorElUser(null);
  };

  useEffect(() => {   
    if(logoutUser){
        navigate('/login')
    }  
}, [logoutUser, navigate]);

  const handleToggleTheme = () => {
    dispatch(toggleThemeMode()); // Dispatch action to toggle theme mode
  };


  const handleCloseNavMenu = () => {
    dispatch(toggleSidebar());
    setAnchorElNav(null);
  };

  const handleLogout = async () => {
    await dispatch(logout());
    dispatch(reset());
    navigate('/login');
  };

  
  if(!suiteDetailsConfirmed){
    return null;
  }
  return (
    <AppBar position="relative" className={styles.appBar} sx={appBarStyle}>
      <Toolbar disableGutters className={styles.toolbar}>
        <Box className={styles.leftSection}>
          <IconButton
            size="large"
            onClick={handleClick}
            color="inherit"
            disableRipple
            className={styles.menuButton}
          >
            {!isSidebarOpen ? 
              <MenuSharpIcon fontSize='medium' /> : 
              <MenuOpenSharpIcon fontSize='medium' />
            }
          </IconButton>
          
          {/* Mobile Menu */}
          {isMobile && (
            <Menu
              id="menu-appbar"
              anchorEl={anchorElNav}
              anchorOrigin={{ vertical: 'bottom', horizontal: 'left' }}
              keepMounted
              transformOrigin={{ vertical: 'top', horizontal: 'left' }}
              open={isSidebarOpen}
              onClose={handleCloseNavMenu}
            >
              {pages.map((page) => (
                <MenuItem key={page.id} onClick={() => {
                  handleCloseNavMenu();
                  navigate(page.link);
                }}>
                  <Typography fontWeight={500} className={styles.mobileMenuItem}>{page.title}</Typography>
                </MenuItem>
              ))}

              {!isOrgUnitRoute && 
              <MenuItem>
                <Box className={styles.mobileMenuBox}>
                  <FormControl className={styles.formControlMobile}>
                    <InputLabel className={styles.selectLabel}>C#</InputLabel>
                    <Select
                      value={company_numbers?.data?.some((item: any) => 
                        item.company_number === companySelection?.company_number
                      ) ? companySelection?.company_number : ''}
                      label="C#"
                      size="small"
                      onChange={(event) => {
                        const selectedCompany = company_numbers?.data?.find(
                          (item: any) => item.company_number === event.target.value
                        );
                        handleCompanyNumberChange({ target: { value: selectedCompany } });
                      }}
                      renderValue={(value: any) => (value ? value : <em>Nothing Selected</em>)}
                      className={styles.selectText}
                      inputProps={{ className: styles.selectInput }}
                    >
                      {company_numbers?.data?.map((item: any) => (
                        <MenuItem sx={{fontWeight: 500}} className={styles.selectMenuItem} key={item?.company_number} value={item.company_number || ''}>
                          {item?.company_number}
                        </MenuItem>
                      ))}
                    </Select>
                  </FormControl>

                  <FormControl className={styles.formControl}>
                    <InputLabel className={styles.selectLabel}>D#</InputLabel>
                    <Select
                      value={division_numbers?.data?.some((item: any) => 
                        item.division_number === divisionSelection?.division_number
                      ) ? divisionSelection?.division_number : ''}
                      label="D#"
                      size="small"
                      onChange={(event) => {
                        const selectedDivision = division_numbers?.data?.find(
                          (item: any) => item.division_number === event.target.value
                        );
                        handleDivisionNumberChange({ target: { value: selectedDivision } });
                      }}
                      renderValue={(value: any) => (value ? value : <em>Nothing Selected</em>)}
                      className={styles.selectText}
                      inputProps={{ className: styles.selectInput }}
                    >
                      {division_numbers?.data?.map((item: any) => (
                        <MenuItem sx={{fontWeight: 500}} className={styles.selectMenuItem} key={item?.division_number} value={item.division_number || ''}>
                          {item?.division_number}
                        </MenuItem>
                      ))}
                    </Select>
                  </FormControl>
                </Box>
              </MenuItem>}
            </Menu>
          )}

          <Box 
            className={styles.logoContainer}
            onClick={() => navigate("/")}
          >
            <Logo 
              className={styles.logo}
              style={{
                fill: theme.palette.mode === 'dark' ? theme.palette.primary.light : theme.palette.primary.main
              }}
            />
          </Box>

          {!isMobile && !isOrgUnitRoute && (
            <Box className={styles.companyDivisionContainer}>
              <FormControl className={styles.selectControl}>
                <InputLabel className={styles.selectLabel}>C#</InputLabel>
                <Select
                  value={company_numbers?.data?.some((item: any) => 
                    item.company_number === companySelection?.company_number
                  ) ? companySelection?.company_number : ''}
                  label="C#"
                  size="small"
                  onChange={(event) => {
                    const selectedCompany = company_numbers?.data?.find(
                      (item: any) => item.company_number === event.target.value
                    );
                    handleCompanyNumberChange({ target: { value: selectedCompany } });
                  }}
                  className={styles.selectText}
                >
                  {company_numbers?.data?.map((item: any) => (
                    <MenuItem 
                      className={styles.selectMenuItem} 
                      key={item?.company_number} 
                      value={item.company_number}
                      sx={{fontWeight: 500}}
                    >
                      {item?.company_number}
                    </MenuItem>
                  ))}
                </Select>
              </FormControl>

              <FormControl className={styles.selectControl}>
                <InputLabel className={styles.selectLabel}>D#</InputLabel>
                <Select
                  value={division_numbers?.data?.some((item: any) => 
                    item.division_number === divisionSelection?.division_number
                  ) ? divisionSelection?.division_number : ''}
                  label="D#"
                  size="small"
                  onChange={(event) => {
                    const selectedDivision = division_numbers?.data?.find(
                      (item: any) => item.division_number === event.target.value
                    );
                    handleDivisionNumberChange({ target: { value: selectedDivision } });
                  }}
                  className={styles.selectText}
                >
                  {division_numbers?.data?.map((item: any) => (
                    <MenuItem 
                      className={styles.selectMenuItem} 
                      key={item?.division_number} 
                      value={item.division_number}
                      sx={{fontWeight: 500}}
                    >
                      {item?.division_number}
                    </MenuItem>
                  ))}
                </Select>
              </FormControl>
            </Box>
          )}
        </Box>

        <Box className={styles.rightSection} 
          style={{
            color: theme.palette.mode === 'dark' ? theme.palette.primary.light : theme.palette.primary.main
          }}
        >
          <IconButton
            className={styles.themeButton}
            onClick={handleToggleTheme}
            color="inherit"
          >
            {themeMode === 'dark' ? <Brightness7Icon /> : <Brightness4Icon />}
          </IconButton>
          
          <Tooltip title="Open settings">
            <IconButton onClick={handleOpenUserMenu} sx={{ p: 0 }}>
              <Avatar alt="user" src="" />
            </IconButton>
          </Tooltip>
          <Menu
            className={styles.userMenu}
            id="menu-appbar"
            anchorEl={anchorElUser}
            anchorOrigin={{
              vertical: 'top',
              horizontal: 'right',
            }}
            keepMounted
            transformOrigin={{
              vertical: 'top',
              horizontal: 'right',
            }}
            open={Boolean(anchorElUser)}
            onClose={handleCloseUserMenu}
          >
            <MenuItem key={0}>
              <Typography className={styles.userMenuTypography}>
                <Link href={userGuideLink} className={styles.userLink} target="_blank" rel="noopener noreferrer">
                  User Guide
                </Link>  
              </Typography>
            </MenuItem>
            <MenuItem key={1} sx={{fontWeight: 500}} onClick={handleLogout}>
              <Typography className={styles.userMenuTypography}>Logout</Typography>
            </MenuItem>
          </Menu>
        </Box>
      </Toolbar>
    </AppBar>
  );
}

export default ResponsiveAppBar;