import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { 
  GridFilterModel, 
  GridSortModel, 
  GridColumnVisibilityModel, 
  GridRowModesModel, 
  GridRowSelectionModel, 
  GridAggregationModel, 
  GridRowGroupingModel, 
  GridPaginationModel,
  GridRowModes,
  GridColDef,
  GridRowId
} from '@mui/x-data-grid-premium';
//import ObjectID from 'bson-objectid';

// Slice Interface
interface GridState {
    context: any;
    index: number;
    rows: any[];
    columns: GridColDef[];
    activeRow: any;
    open: boolean;
    initialState: any;
    rowModesModel: GridRowModesModel;
    rowSelectionModel: GridRowSelectionModel;
    filterModel: GridFilterModel | undefined;
    filterTimeout: number | null;
    paginationModel: GridPaginationModel;
    aggregationModel: GridAggregationModel;
    totalRows: number;
    sortModel: GridSortModel; 
    rowGroupingModel: GridRowGroupingModel;
    columnVisibilityModel: GridColumnVisibilityModel;
    detailPanelExpandedRowIds: GridRowId[];
    filterMode: 'client' | 'server';
    sortingMode: 'client' | 'server';
    paginationMode: 'client' | 'server';
    isLoading: boolean;
    error: string | null;
    confirmDelete: {
        open: boolean;
        row: any | null;
    };
}

const initialState: GridState = {
    context: null, //Grid context
    index: 0,
    rows: [],
    columns: [],
    activeRow: {},
    open: false,
    initialState: null,
    rowModesModel: {}, //
    rowSelectionModel: [],
    filterModel: undefined,
    filterTimeout: null,
    totalRows: 0,
    paginationModel: {
        pageSize: 100,
        page: 0,
    },
    aggregationModel: {},
    sortModel: [{ field: 'created_at', sort: 'desc' }],
    rowGroupingModel: [],
    columnVisibilityModel: {},
    filterMode: 'client',
    sortingMode: 'client',
    paginationMode: 'client',
    detailPanelExpandedRowIds: [],
    isLoading: false,
    error: null,
    confirmDelete: {
        open: false,
        row: null
    }
};

const gridSlice = createSlice({
    name: 'grid',
    initialState,
    reducers: {
        resetGridState: () => initialState,
        setIndex: (state, action: PayloadAction<number>) => {
            state.index = action.payload;
        },
        setInitialState: (state, action: PayloadAction<any>) => {
            state.initialState = action.payload;
        },
        setRows: (state, action: PayloadAction<any>) => {
            state.rows = action.payload;
        },
        setColumns: (state, action: PayloadAction<any>) => {
            state.columns = action.payload;
        },
        setContext: (state, action: PayloadAction<any>) => {
            state.context = action.payload;
        },
        setRowModesModel: (state, action: PayloadAction<GridRowModesModel>) => {
            state.rowModesModel = action.payload;
        },
        setRowSelectionModel: (state, action: PayloadAction<GridRowSelectionModel>) => {
            state.rowSelectionModel = [...action.payload];
        },
        setFilterModel: (state, action: PayloadAction<GridFilterModel>) => {
            state.filterModel = action.payload;
        },
        setPaginationModel: (state, action: PayloadAction<GridPaginationModel>) => {
            state.paginationModel = action.payload;
        },
        setAggregationModel: (state, action: PayloadAction<GridAggregationModel>) => {
            state.aggregationModel = action.payload;
        },
        setSortModel: (state, action: PayloadAction<GridSortModel>) => {
            state.sortModel = action.payload;
        },
        setRowGroupingModel: (state, action: PayloadAction<GridRowGroupingModel>) => {
            state.rowGroupingModel = action.payload;
        },
        setColumnVisibilityModel: (state, action: PayloadAction<GridColumnVisibilityModel>) => {
            state.columnVisibilityModel = action.payload;
        },
        setTotalRows: (state, action: PayloadAction<number>) => {
            state.totalRows = action.payload;
        },
        setDetailPanelExpandedRowIds: (state, action: PayloadAction<GridRowId[]>) => {
            state.detailPanelExpandedRowIds = action.payload;
        },
        initializeGridState: (state, action: PayloadAction<{ 
            context: any, 
            isMobile: boolean 
        }>) => {
            const { context, isMobile } = action.payload;
            
            if (context?.detailView?.initialState?.rowGrouping?.model) {
                state.rowGroupingModel = context.detailView.initialState.rowGrouping.model;
            }
            
            if (context?.detailView?.initialState?.columns?.columnVisibilityModel) {
                state.columnVisibilityModel = context.detailView.initialState.columns.columnVisibilityModel;
            }
            
            if (context?.detailView?.initialState?.sorting?.sortModel) {
                state.sortModel = context.detailView.initialState.sorting.sortModel;
            }
            
            if (context?.detailView?.initialState?.pagination?.paginationModel) {
                state.paginationModel = context.detailView.initialState.pagination.paginationModel;
            } else {
                state.paginationModel = {
                    pageSize: isMobile ? 25 : 100,
                    page: 0,
                };
            }
            
            if (context?.detailView?.initialState?.filtering?.filterModel) {
                state.filterModel = context.detailView.initialState.filtering.filterModel;
            }
            
            if (context?.detailView?.initialState?.aggregation?.aggregationModel) {
                state.aggregationModel = context.detailView.initialState.aggregation.aggregationModel;
            }

            if (context?.detailView?.options?.filterMode) {
                state.filterMode = context.detailView.options.filterMode;
            }

            if (context?.detailView?.options?.sortingMode) {
                state.sortingMode = context.detailView.options.sortingMode;
            }

            if (context?.detailView?.options?.paginationMode) {
                state.paginationMode = context.detailView.options.paginationMode;
            }
        },
        updateFilterModel: (state, action: PayloadAction<GridFilterModel>) => {
            if (state.filterTimeout) {
                clearTimeout(state.filterTimeout);
            }
            state.filterTimeout = window.setTimeout(() => {
                state.filterModel = action.payload;
                state.filterTimeout = null;
            }, 150) as unknown as number;
        },
        cancelRowEdit: (state, action: PayloadAction<string>) => {
            state.rowModesModel = {
                ...state.rowModesModel,
                [action.payload]: { mode: GridRowModes.View, ignoreModifications: true }
            };
        },
        updateRowGroupingWithVisibility: (state, action: PayloadAction<GridRowGroupingModel>) => {
            const newRowGroupingModel = action.payload;
            state.columnVisibilityModel = {
                ...state.columnVisibilityModel,
                ...newRowGroupingModel.reduce((acc: any, field: any) => ({
                    ...acc,
                    [field]: false
                }), {}),
                ...(state.rowGroupingModel || []).reduce((acc: any, field: any) => ({
                    ...acc,
                    [field]: !newRowGroupingModel.includes(field)
                }), {})
            };
            state.rowGroupingModel = newRowGroupingModel;
        },
        setActiveRow: (state, action: PayloadAction<any>) => {
          state.activeRow = action.payload;
        },
        setOpen: (state, action: PayloadAction<boolean>) => {
            state.open = action.payload;
        },
        openConfirmDelete: (state, action: PayloadAction<any>) => {
            state.confirmDelete = {
                open: true,
                row: action.payload
            };
        },
        closeConfirmDelete: (state) => {
            state.confirmDelete = {
                open: false,
                row: null
            };
        }
    }
});

export const {
    setIndex,
    setContext,
    setRows,
    setColumns,
    setRowModesModel,
    setRowSelectionModel,
    setFilterModel,
    setPaginationModel,
    setAggregationModel,
    setSortModel,
    setRowGroupingModel,
    setDetailPanelExpandedRowIds,
    setColumnVisibilityModel,
    setTotalRows,
    updateFilterModel,
    cancelRowEdit,
    updateRowGroupingWithVisibility,
    initializeGridState,
    resetGridState,
    setActiveRow,
    setOpen,
    openConfirmDelete,
    closeConfirmDelete,
    setInitialState
} = gridSlice.actions;

export default gridSlice.reducer;
